import React from 'react';

import './Lead.css'
import Header from '../../componentes/js/Header';
import Footer from '../../componentes/js/Footer';

const Lead = () => {
  return (
    <div>
      <Header />
      <div className="containerContato">
        <div className='tituloContato'>
          <h1 style={{ marginTop: 10 + 'px', fontSize: 2 + 'em'}}>Agradecemos o contato<br/><span style={{fontSize: 1.2 + 'em'}}>Esperamos que você se junte a nós!</span></h1>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Lead;